p > img {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 2rem;
  aspect-ratio: 16 / 9;
}

p img {
  width: 100%;
  object-fit: cover; /* Esto recortará y ajustará la imagen */
}
