@font-face {
  font-family: "EuclidCircularB";
  src: url("../public/fonts/EuclidCircularB-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularB";
  src: url("../public/fonts/EuclidCircularB-Bold.woff2") format("woff2");
  font-weight: italic;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularB";
  src: url("../public/fonts/EuclidCircularB-Medium.woff2") format("woff2");
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: "EuclidCircularB";
  src: url("../public/fonts/EuclidCircularB-Light.woff2") format("woff2");
  font-weight: light;
  font-style: normal;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}

.MuiFilledInput-root {
  background-color: rgba(255, 255, 255, 0.08) !important;
  border-radius: 1rem;
}

.gradient-bg {
  animation: animateBg 10s ease-in-out infinite;
  background-image: linear-gradient(45deg, #000000, #3ad0ff, #000000, #3ad0ff);
  background-size: 300% 100%;
}

.gradient-bg2 {
  outline: 0px solid #f11d6480;
  animation: animateBg 4s ease-in-out infinite;
  background-image: linear-gradient(45deg, #000000, #f11d64, #000000, #f11d64);
  background-size: 300% 100%;
}

.gradient-bg2:hover {
  outline: 5px solid #f11d6480;
  transition: outline 100ms linear;
}

.update_title {
  white-space: nowrap;
  animation: updateTitleAnim 1s ease;
}

.albumGeneratingAnim {
  animation: albumGeneratingAnim 1s infinite;
}

.album_loading_icon {
  animation: albumLoadingIcon 1s infinite;
}

.trend_high {
  animation: trendHighAnim 1s infinite;
}

@keyframes trendHighAnim {
  0% {
    opacity: 100%;
  }

  50% {
    opacity: 30%;
  }

  100% {
    opacity: 100%;
  }
}

@keyframes albumLoadingIcon {
  0% {
    rotate: 0deg;
  }

  100% {
    rotate: 360deg;
  }
}

@keyframes albumGeneratingAnim {
  0% {
    opacity: 0%;
  }

  50% {
    opacity: 50%;
  }

  100% {
    opacity: 0%;
  }
}

@keyframes updateTitleAnim {
  0% {
    color: transparent;
  }

  20% {
    color: white;
  }

  40% {
    color: gray;
  }

  60% {
    color: white;
  }

  100% {
    color: gray;
  }
}

@keyframes animateBg {
  0% {
    background-position: 100% 0%;
  }

  50% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 100% 0%;
  }
}

.css-dlpul0-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root.Mui-disabled {
  background-color: #000 !important;
}

.completed-img {
  animation: animateGeneration 1000ms ease-in-out 1;
}

@keyframes animateGeneration {
  0% {
    filter: brightness(1);
    background-size: 120%;
  }

  25% {
    filter: brightness(2);
  }

  50% {
    filter: brightness(1);
  }

  75% {
    filter: brightness(2);
  }

  100% {
    filter: brightness(1);
    background-size: 100%;
  }
}

.animacion-loading {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(0, 0, 0, 0.1) 60%
  );
  background-size: 200% 200%;
  animation: animacion-loading 1000ms linear infinite;
  border-radius: 1rem;
  backdrop-filter: blur(2px);
  mix-blend-mode: overlay;
}

@keyframes animacion-loading {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 200% 50%;
  }
}
